
import { computed, defineAsyncComponent, PropType, ref } from 'vue';
import { CustomTableColumnsI } from '../interfaces/custom-table.interfaces';
import { ProcessPaymentsI } from '../interfaces/payments.interfaces';

export default {
  name: 'ProcessPayment',
  components: {
    CustomTable: defineAsyncComponent(
      () => import('@/modules/payments/components/CustomTable.vue')
    ),
  },
  props: {
    paymentProcess: {
      type: Array as PropType<ProcessPaymentsI[]>,
      required: false,
    },
  },
  setup(props: any) {
    const columns = ref<CustomTableColumnsI[]>([
      { slot: 'bank', header: 'Banco' },
      { slot: 'payment_date', header: 'Fecha Pago' },
      { slot: 'payment_value', header: 'Valor Pago' },
      { slot: 'canal', header: 'Canal' },
    ]);

    const rows = computed(() => {
      return props.paymentProcess;
    });

    const loading = ref<boolean>(false);

    return {
      rows,
      columns,
      loading,
    };
  },
};
